module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@weahead/gatsby-theme-princess-cake/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://fika.hemsida.eu/elise/graphql"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
